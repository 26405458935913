var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},_vm._l((_vm.smallsDef),function(smallEl,i){return _c('v-col',_vm._b({key:i},'v-col',smallEl.colDef,false),[_c('v-card',{staticClass:"d-flex align-center my-0 mx-1",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"mx-2 d-flex justify-center px-0"},[_c('v-progress-circular',{attrs:{"color":(smallEl.value / smallEl.compareVal) * 100 - 100 > 0
									? 'success'
									: 'error',"value":Math.abs((smallEl.value / smallEl.compareVal) * 100 - 100)}})],1),_c('v-col',{staticClass:"mx-2 align-center px-0",attrs:{"cols":"9"}},[_c('div',{staticClass:"d-block text-truncate"},[_vm._v(_vm._s(smallEl.title))]),_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(smallEl.prefix)+" "+_vm._s(smallEl.filter ? smallEl.filter(smallEl.value) : smallEl.value)+_vm._s(smallEl.postfix)+" ")]),_c('span',{staticClass:"ml-2 text-caption d-flex align-center",class:`${
									(smallEl.value / smallEl.compareVal) * 100 - 100 > 0
										? 'success'
										: 'error'
								}--text`},[_vm._v(" "+_vm._s(Math.round( (smallEl.value / smallEl.compareVal) * 100 - 100 ))+"% ")])])])],1)])],1)}),1),_c('v-row',{staticClass:"pt-2 mb-2",attrs:{"dense":""}},_vm._l((_vm.midDefs),function(midEl,i){return _c('v-col',{key:i,attrs:{"cols":"12","sm":"6","lg":"3"}},[_c('v-card',{staticClass:"mt-0 mx-1 fill-height"},[_c('v-card-title',[_vm._v(_vm._s(midEl.title))]),_c('div',{staticClass:"mx-4 mt-7 mb-4 d-flex flex-row align-center"},[_c('v-progress-linear',{staticClass:"px-4",attrs:{"rounded":"","height":"4","color":(midEl.value / midEl.compareVal) * 100 - 100 > 0
								? 'success'
								: 'error',"value":Math.abs(
								Math.round((midEl.value / midEl.compareVal) * 100 - 100)
							)}})],1),_c('v-card-text',{staticClass:"py-0 d-flex align-center"},[_c('span',{staticClass:"text-h4"},[_vm._v(_vm._s(midEl.value))]),_c('span',{staticClass:"text-h7 ml-2",class:(midEl.value / midEl.compareVal) * 100 - 100 > 0
								? 'success--text'
								: 'error--text'},[_vm._v(" "+_vm._s(Math.round((midEl.value / midEl.compareVal) * 100))+"% ")])])],1)],1)}),1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"mx-1 px-4 py-2 mt-0 fill-height"},[_c('v-card-title',[_vm._v("Bets")]),_c('div',{staticStyle:{"height":"83%"}},[_c('chart-bar',{tag:"Component",attrs:{"data":_vm.chartData,"options":_vm.chartOptions}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-card',{staticClass:"mx-1 px-4 py-0 mt-0 fill-height"},[_c('v-card-title',[_vm._v("Netto")]),_c('div',{staticStyle:{"height":"83%"}},[_c('chart-line',{tag:"Component",attrs:{"data":_vm.chartData,"options":_vm.chartOptions}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","lg":"4"}},[_c('v-card',{staticClass:"mx-1 px-4 py-0 mt-0 fill-height d-flex flex-column"},[_c('v-card-title',{staticClass:"align-center d-flex flex-row"},[_c('v-switch',{staticClass:"mt-0",attrs:{"hide-details":""},model:{value:(_vm.pieDataPart),callback:function ($$v) {_vm.pieDataPart=$$v},expression:"pieDataPart"}}),_c('div',{staticClass:"ml-2 mt-1"},[_vm._v(" "+_vm._s(_vm.pieDataPart ? "Screens" : "Devices")+" ")])],1),_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"83%"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('chart-pie',{ref:"piePie",tag:"Component",attrs:{"data":_vm.pieData,"options":_vm.pieOptions}})],1),_c('v-col',[_c('div',{staticClass:"d-flex flex-column"},_vm._l((this.pieDataPart
									? _vm.pieLabels2
									: _vm.pieLabels),function(label,i){return _c('span',{key:i,staticClass:"mb-1",staticStyle:{"user-select":"none"},on:{"mouseenter":function($event){return _vm.triggerHover(i)},"mouseleave":function($event){return _vm.triggerHover(-1)}}},[_c('v-icon',{staticClass:"mr-2",style:(`color: ${_vm.getColor(i)} !important`)},[_vm._v(" "+_vm._s(label.icon)+" ")]),_c('span',[_vm._v(_vm._s(label.name))])],1)}),0)])],1)],1)],1)],1),_c('v-row',{staticClass:"mt-1"},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card',{staticClass:"mt-0 fill-height"},[_c('v-card-title',[_vm._v("Sessions")]),_c('v-data-table',{attrs:{"dense":"","items":_vm.tableItems,"headers":_vm.tableHeaders,"hide-default-footer":""},scopedSlots:_vm._u([{key:`item.sum_bet`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Number")(item.sum_bet,{ currency: item.currency }))+" ")]}},{key:`item.sum_win`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Number")(item.sum_bet,{ currency: item.currency }))+" ")]}},{key:`item.netto`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("Number")(item.sum_bet,{ currency: item.currency }))+" ")]}}],null,true)})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-card',{staticClass:"mt-0 fill-height"},[_c('v-card-title',[_vm._v("Rounds")]),_c('v-data-table',{attrs:{"dense":"","items":_vm.tableItems2,"headers":_vm.tableHeaders2,"hide-default-footer":""}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }